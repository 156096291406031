<template>
  <el-container style="height: 100%; width: 100%">
    <el-card
      class="iot-person public-page-style iot-search-header"
      style="height: 100%"
    >
      <div class="payParamTitle" style="width: 100%">
        <!--  v-if="
            $store.state.permissionArr.indexOf('templatePayParam:add') != -1
          " -->
        <el-button type="primary" size="mini" @click="addPayParam()" plain
          >新增模板</el-button
        >
      </div>
      <el-table :data="tableData" style="width: 100%" height="650">
        <el-table-column prop="payParamName" label="支付方案名称">
        </el-table-column>
        <el-table-column prop="planName" label="绑定方案"> </el-table-column>
        <el-table-column label="支付类型">
          <template slot-scope="scope">
            <div v-if="(scope.row.payType = 0)">微信支付</div>
            <div v-else>余额支付</div>
          </template>
        </el-table-column>
        <el-table-column label="支付模式">
          <template slot-scope="scope">
            <div v-if="(scope.row.paySetMeal = 0)">下发数量</div>
            <div v-else>下发数值</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建日期"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="setParam(scope.row.payParamId)"
              plain
              >二维码管理</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="getPayParamId(scope.row.payParamId)"
              plain
              >修改</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="delPayParam(scope.row.payParamId)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="payParamTitle" style="width: 100%">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageForm.current"
          :page-sizes="[10, 20, 30, 40]"
          :total="pageForm.total"
          :page-size="pageForm.size"
          style=""
          layout="total, sizes, prev, pager, next, jumper"
          @prev-click="
            () => {
              $set(pageForm, 'current', pageForm.current - 1);
              getPagePayParam();
            }
          "
          @next-click="
            () => {
              $set(pageForm, 'current', pageForm.current + 1);
              getPagePayParam();
            }
          "
        >
        </el-pagination>
      </div>
      <el-dialog top="3%" :visible.sync="dialogVisible" width="60%">
        <div
          class="dialogBody"
          style="height: 500px; overflow-y: auto; overflow-x: hidden"
        >
          <div style="width: 100%">
            <el-form
              :model="payParamFrom"
              ref="payParamFrom"
              label-width="150px"
              class="demo-payParamFrom"
            >
              <el-form-item label="支付方案名称:">
                <div style="width: 217px">
                  <el-input
                    v-model="payParamFrom.payParamName"
                    placeholder="请输入支付名称"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item v-if="editVis" label="绑定方案:">
                <el-cascader
                  v-if="planList.length != 0"
                  class="lzlinks-cascader"
                  :props="props"
                  :options="planList"
                  @change="cascaderChange"
                  v-model="selectList"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="支付类型:">
                <el-radio-group
                  v-model="payParamFrom.payType"
                  @change="setPayType"
                >
                  <el-radio :label="0">微信支付</el-radio>
                  <el-radio :label="1">余额支付</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="支付模式:">
                <el-radio-group
                  v-model="payParamFrom.paySetMeal"
                  @change="chanePaySet"
                >
                  <el-radio :label="1">下发固定值</el-radio>
                  <el-radio :label="0">下发用户扫码时择的数量</el-radio>
                  <el-radio v-show="payParamFrom.payType == 1" :label="2"
                    >返回值后付款</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item label="上传背景图:">
                <el-form ref="imgUpload">
                  <el-upload
                    class="avatar-uploader"
                    :action="platformImgUpload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :data="{}"
                  >
                    <img
                      v-if="payParamFrom.imgUrl"
                      :src="
                        constant.OSS_URL +
                        constant.wechatimg_IMG +
                        payParamFrom.imgUrl
                      "
                      class="avatar"
                    />
                    <img
                      class="avatar"
                      v-else-if="imgUrl"
                      :src="constant.OSS_URL + constant.wechatimg_IMG + imgUrl"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form>
              </el-form-item>
            </el-form>
          </div>

          <div v-if="payParamList">
            <div class="payParamItem">
              <div>支付名称</div>
              <div>金 额</div>
              <div>下 发 值</div>
              <!-- <div>备 注</div> -->
              <!-- <div>元件</div> -->
              <div>下发参数</div>
              <div>返回参数</div>
              <div v-if="payParamFrom.paySetMeal == 2">返回金额参数</div>
              <div v-if="payParamFrom.paySetMeal != 2">库存参数</div>
              <div>操作</div>
            </div>
            <div
              class="payParamItem"
              v-for="(item, index) in payParamFrom.payParamMoneyList"
              :key="index"
            >
              <div>
                <el-input
                  v-model="item.payName"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div>
                <el-input-number
                  v-if="payParamFrom.paySetMeal != 2"
                  :precision="2"
                  :controls="false"
                  v-model="item.moneyNum"
                  placeholder="请输入内容"
                ></el-input-number>
                <div v-else>=返回金额</div>
              </div>
              <div>
                <el-input-number
                  :precision="0"
                  v-if="payParamFrom.paySetMeal == 1"
                  :controls="false"
                  v-model="item.sendValue"
                  placeholder="请输入内容"
                ></el-input-number>
                <div v-else-if="payParamFrom.paySetMeal == 0">=数量</div>
                <div v-else>=余额</div>
              </div>
              <!-- <div>
                <el-input
                  v-model="item.payRemark"
                  placeholder="请输入内容"
                ></el-input>
              </div> -->
              <!--<div>
                 <el-select
                  v-model="item.subOriginalId"
                  @change="setOriginal(item)"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in originalArr"
                    :key="item.subOriginalId"
                    :label="item.subOriginalName"
                    :value="item.subOriginalId"
                  >
                  </el-option>
                </el-select> 
              </div>-->
              <div>
                <el-select
                  v-model="item.paramId"
                  @focus="setTodayParam(item.paramId)"
                  @change="setParamId(item, item.paramId)"
                  placeholder="请选择"
                >
                  <el-option
                    :disabled="checkSelect.indexOf(element.paramId) != -1"
                    v-for="element in paramArrList"
                    :key="element.paramId"
                    :label="element.paramName"
                    :value="element.paramId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-select
                  v-model="item.reParamId"
                  @focus="setTodayParam(item.reParamId)"
                  @change="setParamId(item, item.reParamId)"
                  placeholder="请选择"
                >
                  <el-option
                    :disabled="checkSelect.indexOf(element.paramId) != -1"
                    v-for="element in paramArrList"
                    :key="element.paramId"
                    :label="element.paramName"
                    :value="element.paramId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-if="payParamFrom.paySetMeal != 2">
                <el-select
                  v-model="item.viParamId"
                  @focus="setTodayParam(item.viParamId)"
                  @change="setParamId(item, item.viParamId)"
                  placeholder="请选择"
                >
                  <el-option
                    :disabled="checkSelect.indexOf(element.paramId) != -1"
                    v-for="element in paramArrList"
                    :key="element.paramId"
                    :label="element.paramName"
                    :value="element.paramId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-if="payParamFrom.paySetMeal == 2">
                <el-select
                  v-model="item.sParamId"
                  @focus="setTodayParam(item.sParamId)"
                  @change="setParamId(item, item.sParamId)"
                  placeholder="请选择"
                >
                  <el-option
                    :disabled="checkSelect.indexOf(element.paramId) != -1"
                    v-for="element in paramArrList"
                    :key="element.paramId"
                    :label="element.paramName"
                    :value="element.paramId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-button
                  type="danger"
                  size="mini"
                  plain
                  @click="delMoneyList(index)"
                  >删除</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="addMoneyList()"
            >新增参数</el-button
          >
          <el-button type="primary" size="mini" @click="PayParam"
            >确 定</el-button
          >
          <el-button size="mini" @click="dialogVisible = false"
            >取 消</el-button
          >
        </span>
      </el-dialog>
      <el-dialog top="3%" :visible.sync="paramListVisible" width="60%">
        <div class="dialogBody">
          <el-table :data="paramList" stripe height="500" style="width: 100%">
            <el-table-column prop="projectName" label="项目名称">
            </el-table-column>
            <el-table-column prop="dtuSn" label="SN"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="downloadQrCode(scope.row)"
                  plain
                  >下载二维码</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="qrcode" id="qrcode" />
          <div class="payParamTitle">
            <el-pagination
              background
              @size-change="paramSizeChange"
              @current-change="paramCurrentChange"
              :current-page="pageForm.current"
              :page-sizes="[10, 20, 30, 40]"
              :total="pageForm.total"
              :page-size="pageForm.size"
              style=""
              layout="total, sizes, prev, pager, next, jumper"
              @prev-click="
                () => {
                  $set(pageForm, 'current', pageForm.current - 1);
                  getParamList();
                }
              "
              @next-click="
                () => {
                  $set(pageForm, 'current', pageForm.current + 1);
                  getParamList();
                }
              "
            >
            </el-pagination>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </el-container>
</template>

<script>
import { baseUrl } from "../util/global";
import QRCode from "qrcodejs2";
import { payUrl } from "../util/global";
import { OSS_URL, wechatimg_IMG } from "../util/constant";
let that;
export default {
  data() {
    return {
      paramArrList: [],
      default: {},
      editVis: true,
      upState: 0,
      todayParam: "",
      checkSelect: [],
      imgUrl: "",
      constant: {
        OSS_URL,
        wechatimg_IMG,
      },
      platformImgUpload: baseUrl + "img?typeName=wechatimg",
      code: {
        payParamId: "",
        sn: "",
      },
      paramList: [],
      paramForm: {
        current: 1,
        size: 10,
        total: 0,
        planId: "",
      },
      paramListVisible: false,
      payParamList: true,
      originalArr: [],
      selectList: [],
      dialogVisible: false,
      tableData: [],
      payParamFrom: {
        imgUrl: "",
        payParamName: "",
        planId: "",
        gatewayId: "",
        payType: 0,
        paySetMeal: 0,
        payParamMoneyList: [],
      },
      planList: [],
      pageForm: {
        current: 1,
        size: 10,
        total: 0,
      },
      props: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: false,
        lazyLoad(node, resolve) {
          if (node.level == 1) {
            that.getGatewayList(node.data.value);
          } else if (node.level == 2) {
            that.gatOriginalArr(node.data.value);
          }
          setTimeout(() => {
            let nodes = [];
            if (node.level == 1) {
              nodes = that.gatewayList;
            } else if (node.level == 2) {
              nodes = that.originalArr;
            }
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 500);
        },
      },
    };
  },
  methods: {
    chanePaySet() {
      if (that.payParamFrom.paySetMeal == 2) {
        that.payParamFrom.payParamMoneyList.forEach((item) => {
          that.checkSelect = that.checkSelect.filter((element) => {
            return item.viParamId != element;
          });
        });
      } else {
        that.payParamFrom.payParamMoneyList.forEach((item) => {
          that.checkSelect = that.checkSelect.filter((element) => {
            return item.sParamId != element;
          });
        });
      }

    },

    // 判断用户选择支付模式进行相应的更改
    setPayType() {
      if (that.payParamFrom.payType == 0 && that.payParamFrom.paySetMeal == 2) {
        that.payParamFrom.paySetMeal = 0;
      }
    },

    handleAvatarSuccess(res) {
      if (200 == res.code) {
        that.imgUrl = res.data;
        that.$set(that.payParamFrom, "imgUrl", that.imgUrl);
      }
    },
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isImg) {
        this.$message.error("图片格式仅限jpeg，jpg，png，gif!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片大小不得超过2MB!");
      }
      return isImg && isLt2M;
    },

    cascaderChange: function (val) {
      that.$set(that.payParamFrom, "planId", val[0]);
      that.$set(that.payParamFrom, "gatewayId", val[1]);
      that.$set(that.payParamFrom, "subOriginalId", val[2]);
      that.originalArr = [];
      that.payParamFrom.payParamMoneyList.forEach((item) => {
        item.paramId = "";
        item.reParamId = "";
        item.viParamId = "";
        item.sParamId = "";
      });

      that.changeOriginal(val[2]);
    },
    //分页方法
    handleSizeChange(val) {
      that.pageForm.size = val;
      that.getPagePayParam();
    },
    handleCurrentChange(val) {
      that.pageForm.current = val;
      that.getPagePayParam();
    },
    //分页方法
    paramSizeChange(val) {
      that.pageForm.size = val;
      that.getParamList();
    },
    paramCurrentChange(val) {
      that.pageForm.current = val;
      that.getParamList();
    },
    // 获取列表分页
    getPagePayParam() {
      that.$api.payParam.pagePayParam(that.pageForm).then((res) => {
        if (res.code == 200) {
          that.tableData = res.data.records;
          that.pageForm.total = res.data.total;
        }
      });
    },
    getPayParamId(id) {
      that.editVis = false;
      that.checkSelect = [];
      let obj = {
        payParamId: id,
        dtuSn: "",
      };
      that.getPayParam(obj);
    },
    setParam(id) {
      that.code.payParamId = id;
      that.paramForm.planId = id;
      that.paramForm.current = 1;
      that.paramForm.size = 10;
      that.getParamList();
    },
    getParamList() {
      that.$api.payParam.PayParamList(that.paramForm).then((res) => {
        if (res.code == 200) {
          that.paramList = res.data.records;
          that.paramForm.total = res.data.total;
          that.paramListVisible = true;
        }
      });
    },
    addPayParam() {
      that.editVis = true;
      that.checkSelect = [];
      that.selectList = [];
      that.payParamFrom = {
        payParamName: "",
        planId: "",
        gatewayId: "",
        payType: 0,
        paySetMeal: 0,
        payParamMoneyList: [],
      };
      that.dialogVisible = true;
    },
    delMoneyList(num) {
  
      that.payParamFrom.payParamMoneyList =
        that.payParamFrom.payParamMoneyList.filter((item, index) => {
          if (index == num) {
          that.checkSelect = that.checkSelect.filter((element) => {
            return item.viParamId != element||item.sParamId != element||item.reParamId!=element||item.paramId!=element;
          });
          }
          return index != num;
        });
      that.upState = 1;
    },
    // 获取方案详情
    getPayParam(data) {
      that.checkSelect = [];
      that.payParamFrom.payParamMoneyList = [];
      that.$api.payParam.getPayParam(data).then((res) => {
        if (res.code == 200) {
          let data = JSON.parse(JSON.stringify(res.data));
          that.default = JSON.parse(JSON.stringify(data));
          that.selectList = [data.planId, data.gatewayId];

          // that.editGatewayArr(that.selectList);

          that.changeOriginal(data.subOriginalId);
          data.payParamMoneyList.forEach((item) => {
            that.checkSelect.push(item.reParamId);
            that.checkSelect.push(item.paramId);
            if (data.paySetMeal == 2) {
              that.checkSelect.push(item.sParamId);
            } else {
              that.checkSelect.push(item.viParamId);
            }
          });
          // setTimeout(() => {
          that.payParamFrom = data;
          that.dialogVisible = true;
          // }, 300);
        }
      });
    },
    // editGatewayArr(v) {
    //   that.$api.plan.getPlanList().then((res) => {
    //     if (res.code == 200) {
    //       that.planList = [];
    //       let newOpts1 = [];
    //       res.data.forEach((element) => {
    //         newOpts1.push({
    //           value: element.planId,
    //           label: element.planName,
    //         });
    //       });

    //       that.$api.gateway.getGatewayList(v[0]).then((res1) => {
    //         if (res1.code == 200) {
    //           that.gatewayList = [];
    //           let newOpts = [];

    //           res1.data.forEach((element) => {
    //             newOpts.push({
    //               value: element.gatewayId,
    //               label: element.gatewayName,
    //               leaf: true,
    //             });
    //           });
    //           that.$api.plan.getOriginalArr(v[1]).then((res) => {
    //             if (res.code == 200) {
    //               that.originalArr = [];
    //               let newOpts2 = [];
    //               res.data.forEach((element) => {
    //                 newOpts2.push({
    //                   value: element.subOriginalId,
    //                   label: element.subOriginalName,
    //                   leaf: true,
    //                 });
    //               });
    //               for (let i = 0; i < newOpts.length; i++) {
    //                 if (newOpts[i].value == v[1]) {
    //                   that.$set(newOpts[i], "children", newOpts2);
    //                 }
    //               }
    //               for (let i = 0; i < newOpts1.length; i++) {
    //                 if (newOpts1[i].value == v[0]) {
    //                   that.$set(newOpts1[i], "children", newOpts);
    //                 }
    //               }

    //               that.planList = newOpts1;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    // },
    // 修改方案
    PayParam() {
      if (!that.payParamFrom.payParamName) {
        that.$message({
          type: "error",
          message: "请输入支付方案名称！",
        });
        return;
      }
      if (!that.payParamFrom.planId || !that.payParamFrom.gatewayId) {
        that.$message({
          type: "error",
          message: "请绑定方案！",
        });
        return;
      }
      that.payParamFrom.payParamMoneyList.forEach((item) => {
        if (!item.payName) {
          that.$message({
            type: "error",
            message: "请输入支付名称！",
          });
          return;
        }
        if (!item.paramId) {
          that.$message({
            type: "error",
            message: "请选择下发参数！",
          });
          return;
        }
        if (!item.reParamId) {
          that.$message({
            type: "error",
            message: "请选择上传参数！",
          });
          return;
        }
        if (that.payParamFrom.paySetMeal != 2 && !item.viParamId) {
          that.$message({
            type: "error",
            message: "请选择库存参数！",
          });
          return;
        }
        if (that.payParamFrom.paySetMeal == 2 && !item.sParamId) {
          that.$message({
            type: "error",
            message: "请选择返回金额参数！",
          });
          return;
        }
        if (!item.moneyNum && item.moneyNum != 0&&that.payParamFrom.paySetMeal != 2) {
          that.$message({
            type: "error",
            message: "请输入金额！",
          });
          return;
        }
        if (!item.sendValue && item.sendValue != 0 &&that.payParamFrom.paySetMeal == 1) {
          that.$message({
            type: "error",
            message: "请输入下发值！",
          });
          return;
        }
        if (that.payParamFrom.paySetMeal == 2&&!item.sParamId) {
           that.$message({
            type: "error",
            message: "选择返回金额参数地址！",
          });
          return;
        }
        if (that.payParamFrom.paySetMeal != 2&&!item.viParamId) {
           that.$message({
            type: "error",
            message: "选择库存参数地址！",
          });
          return;
        }
        if (!that.editVis) {
          if (!item.payId && item.payId != 0) {
            that.upState = 1;
          }
          if (that.upState == 0) {
            that.default.payParamMoneyList.forEach((element) => {
              if (
                element.payId == item.payId &&
                (element.viParamId != item.viParamId ||
                  element.reParamId != item.reParamId ||
                  element.sParamId != item.sParamId)
              ) {
                that.upState = 1;
              }
            });
          }
          that.payParamFrom["upState"] = that.upState;
        }
      });
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.payParamFrom.payParamMoneyList.forEach(item=>{
        if (that.payParamFrom.paySetMeal == 2) {
          delete item.viParamId;
          delete item.moneyNum;
          delete item.sendValue;
        }else if (that.payParamFrom.paySetMeal != 2) {
          delete item.sParamId;
        }
        if (that.payParamFrom.paySetMeal == 0) {
          delete item.sendValue;
        }

      })
      let api = that.payParamFrom.payParamId
        ? that.$api.payParam.editPayParam(that.payParamFrom)
        : that.$api.payParam.addPayParam(that.payParamFrom);
      api.then((res) => {
        loading.close();
        if (res.code == 200) {
          that.payParamFrom = {
            payParamName: "",
            planId: "",
            gatewayId: "",
            payType: 0,
            paySetMeal: 0,
            payParamMoneyList: [],
          };
          that.selectList = [];
          that.getPagePayParam();
          that.dialogVisible = false;
        }
      });
    },
    // 删除方案
    delPayParam(id) {
      this.$confirm("确定要删除该方案？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        that.$api.payParam.delPayParam(id).then((res) => {
          loading.close();
          if (res.code == 200) {
            that.getPagePayParam();
          }
        });
      });
    },
    // 获取绑定方案列表
    getPlanList() {
      that.$api.plan.getPlanList().then((res) => {
        if (res.code == 200) {
          that.planList = [];
          let newOpts = [];
          res.data.forEach((element) => {
            newOpts.push({
              value: element.planId,
              label: element.planName,
            });
          });

          that.planList = newOpts;
        }
      });
    },
    // 获取绑定方案下网关列表
    getGatewayList(planId) {
      that.$api.gateway.getGatewayList(planId).then((res) => {
        if (res.code == 200) {
          that.gatewayList = [];
          let newOpts = [];
          res.data.forEach((element) => {
            newOpts.push({
              value: element.gatewayId,
              label: element.gatewayName,
            });
          });
          that.gatewayList = newOpts;
        }
      });
    },
    addMoneyList() {
      if (that.payParamFrom.payParamMoneyList.length >= 10) {
        that.$message({
          type: "error",
          message: "支付参数不能超过十条！",
        });
        return;
      }
      that.payParamFrom.payParamMoneyList.push({
        payName: "",
        paramId: "",
        reParamId: "",
        viParamId: "",
        sParamId: "",
        moneyNum: "",
        sendValue: "",
        payRemark: "",
      });
    },
    gatOriginalArr(data) {
      that.$api.plan.getOriginalArr(data).then((res) => {
        if (res.code == 200) {
          that.originalArr = [];
          let newOpts = [];
          res.data.forEach((element) => {
            newOpts.push({
              value: element.subOriginalId,
              label: element.subOriginalName,
              leaf: true,
            });
          });
          that.originalArr = newOpts;
        }
      });
    },

    changeOriginal(subOriginalId) {
      that.$api.parameter.getParamArr(subOriginalId).then((response) => {
        if (response.code == 200) {
          that.paramArrList = response.data;
          console.log(that.paramArrList);
        }
      });
    },
    setTodayParam(id) {
      that.todayParam = id;
    },
    setParamId(item, id) {
      // console.log(that.todayParam);
      that.checkSelect = that.checkSelect.filter((element) => {
        return element != that.todayParam;
      });
      that.todayParam=id;
      that.checkSelect.push(id);
    },
    // 下载二维码
    downloadQrCode(row) {
      document.getElementById("qrcode").innerHTML = "";
      // console.log(document.getElementById("qrcode"));
      that.code.sn = row.dtuSn;
      that.createQrCode(row.dtuSn);
      //获取canvas标签
      let canvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      // canvas.drawImage(img, 0, 0, 200, vCtrlLight.h)
      //创建a标签
      let a = document.createElement("a");
      //获取二维码的url并赋值为a.href
      a.href = canvas[0].toDataURL("img/png");
      //设置下载文件的名字
      a.download = row.projectName;
      setTimeout(() => {
        a.click();
      }, 200);
      //点击事件，相当于下载
    },
    createQrCode() {
      //创建二维码
      new QRCode("qrcode", {
        width: 200,
        height: 200,
        colorDark: "#000000", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
        text:
          payUrl +
          "Wechatpay?dtuSn=" +
          that.code.sn +
          "&payParamId=" +
          that.code.payParamId,
      });
    },
  },
  mounted() {
    that = this;
    that.getPagePayParam();
    that.getPlanList();
  },
};
</script>

<style scoped>
.iot-person {
  box-sizing: border-box;
}
.payParamTitle {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 10px 20px;
}
.payParamItem {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 5px 0;
}
.payParamItem > div {
  width: 15%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 5px;
}
::v-deep .el-input-number {
  width: 100% !important;
}
.qrcode {
  display: none;
}
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.dialogBody::-webkit-scrollbar {
  width: 10px;
  /*对垂直流动条有效*/
  height: 10px;
  /*对水平流动条有效*/
}

/*定义滑块颜色、内阴影及圆角*/
.dialogBody::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #aaaaaacc;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.avatar-uploader:hover {
  border-color: #409eff !important;
}
.avatar,
.avatar-uploader {
  width: 140px;
  height: 140px;
  display: block;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
}
</style>